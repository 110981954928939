.current{
  color: skyblue;
  font-weight: 700;
}

.not-current{
  font-weight: 300;
}

.number {
  cursor: pointer;
}